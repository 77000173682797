import React from "react";
import Layout from "../../components/layout";
import Button from "../../components/button";
import { P, TextBlockHeading, PageHeading } from "../../components/typography";
import { FaCheckCircle } from "react-icons/fa";
import { StaticImage } from "gatsby-plugin-image";

const Emtt = () => {
  const textData = [
    {
      header:
        "Transform Your Health with Extracorporeal Magnetic Tissue Treatment (EMTT)",
      body: (
        <>
          <P margin="mb-6 pl-0">
            Experience relief from chronic pain and enhance your quality of
            life.
          </P>
          <P margin="mb-6">
            Are you struggling with chronic musculoskeletal pain or sports
            injuries? Our innovating Extracorporeal Magnetotransduciton Therapy
            (EMTT) offers a cutting-edge solution to revitalize your health and
            restore your body's natural balance.
          </P>
        </>
      )
    },
    {
      header: "What is EMTT?",
      body: (
        <>
          <P margin="mb-6">
            EMTT is a revolutionary, non-invasive treatment designed to target
            disorders of the musculoskeletal system, including{" "}
            <strong>back pain</strong>, <strong>osteoarthritis</strong>, and{" "}
            <strong>sports-related injuries</strong>. Unlike traditional
            treatments, EMTT uses high-frequency magnetic pulses, delivering
            effective transduction power to enhance your body's healing process.
          </P>
        </>
      )
    },
    {
      header: "How Does EMTT Work?",
      body: (
        <>
          <P margin="mb-6">
            Our EMTT therapy is a comfortable and risk-free experience. During
            the session, a therapy loop is positioned over the affected area
            while you remain fully clothed – ensuring your comfort without the
            need for direct skin contact. The high-energy magnetic pulses
            penetrate deeply, stimulating your body's natural healing
            mechanisms.
          </P>
          <P>
            Each session lasts between 5 and 20 minutes, with a typical course
            involving 6 to 8 sessions. The number of sessions varies based on
            the severity of your condition and your body's response to the
            therapy.
          </P>
        </>
      )
    },
    {
      header: "Benefits of EMTT",
      body: (
        <>
          <ul className="mb-6">
            <li>
              - Targeted Relief: Effectively treats a range of conditions,
              including osteoarthritis, herniated discs, chronic back pain, and
              sports injuries.
            </li>
            <li>
              - Non-Invasive: A comfortable, clothed procedure with no direct
              skin contact required.
            </li>
            <li>
              - Quick Sessions: Each session is brief, fitting conveniently into
              your busy schedule.
            </li>
            <li>
              - Safe and Comfortable: A largely risk-free treatment with minimal
              side effects.
            </li>
          </ul>
        </>
      )
    },
    {
      header: "Ready to Embrace a Pain-Free Life?",
      body: (
        <>
          <P margin="mb-6">Ready to Embrace a Pain-Free Life?</P>
          <P margin="mb-6">
            Don't let chronic pain control your life. Book your appointment
            today and take the first step towards a healthier, more active you
            with our EMTT therapy.
          </P>
          <Button linkTo="/contact-us">Book now</Button>
        </>
      )
    }
  ];

  return (
    <Layout>
      <div className="flex justify-center">
        <div className="text-left">
          <div className="px-6 xs:px-12 sm:px-20 md:px-32 lg:max-w-4xl">
            <div>
              <PageHeading className="mb-4 rounded-sm">
                Our newest therapy: EMTT
              </PageHeading>
              <StaticImage
                src="../../images/emtt_feet.jpg"
                layout="fullWidth"
                className="mt-2 rounded-sm"
                width={400}
                height={300}
                alt=""
              />

              <div className="mt-2 text-gray-500 mb-6">
                EMTT (Extracorporeal Magnetotransduction Therapy) is the latest
                addition to our advanced treatment portfilio. EMTT is a leap
                forward in non-invasive treatment options, offering a new
                solution for patients with musculoskeletal pain and various
                chornic conditions.
              </div>
              <div className="mb-8">
                <div
                  className="grid gap-x-2 gap-y-4"
                  style={{
                    gridTemplateColumns: "auto 1fr"
                  }}
                >
                  <FaCheckCircle className="inline-block self-center w-4 h-4 text-green-500" />
                  Non-invasive: EMTT uses magnetic fields to stimulate cellular
                  repair and regeneration, offering a treatment that does not
                  require surgery or medication.
                  <FaCheckCircle className="inline-block w-4 self-center h-4 text-green-500" />
                  Targeted Relief: Designed to target areas of pain and
                  inflammation accurately, EMTT provides focused care for better
                  outcomes.
                  <FaCheckCircle className="inline-block w-4 self-center h-4 text-green-500" />
                  Quick Sessions: Each session is brief, fitting conveniently
                  into your busy schedule.
                </div>
              </div>
              <Button linkTo="/contact-us">Book a consultation</Button>
            </div>
            <div className="relative flex-shrink-0 flex-grow"></div>
          </div>

          <div className="px-6 xs:px-12 sm:px-20 md:px-32 lg:max-w-4xl pt-12">
            <TextBlockHeading wide>Complementary therapies</TextBlockHeading>
            <div>
              <strong>Synergistic with shockwave therapy:</strong> Enhance the
              effectiveness of your treatment plan with a combination of EMTT
              and shockwave therapy.<br></br>
              <strong>Tailored treatment plans:</strong> We design our therapies
              to meet your unique needs, ensuring a comprehensive approach to
              treatment and recovery.<br></br>
            </div>
          </div>
          <div className="px-6 xs:px-12 sm:px-20 md:px-32 lg:max-w-4xl pt-12">
            <TextBlockHeading wide>Your path to recovery</TextBlockHeading>
            <div>
              <strong>Initial consultation:</strong>Discuss your health concerns
              and goals with our experts and explore how EMTT can be integrated
              into your treatment<br></br>
              <strong>Customized Care:</strong> Receive a personalized care plan
              that combines EMTT with other therapies and approaches offered at
              our clinic.<br></br>
              <strong>Ongoing Support:</strong>Our team provides continuous
              support and adjustments to your treatment plan, ensuring optimal
              results.
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Emtt;
